export enum BizCode {
  AcOsd = 'ac_osd',
  AcState = 'ac_state',
  AcEvent = 'ac_events',
  AcFlightTaskProgressEvent = 'ac_flighttask_progress_event',
  RcOsd = 'rc_osd',
  RcState = 'rc_state',
  RcEvent = 'rc_events',
  DockOsd = 'dock_osd',
  DockState = 'dock_state',
  DockEvent = 'dock_events',
  DeviceOnline = 'device_online',
  DeviceOffline = 'device_offline',
  VerificationFailed = 'verification_failed',
  FmMissionResult = 'fm_mission_result',
  FmMissionProgress = 'fm_mission_progress',
  JourneyEvent = 'journey_event'
}

export enum DrcMethod {
  TAKE_PHOTO = 'camera_photo_take',
  ZOOM_IN = 'camera_zoom_in',
  ZOOM_OUT = 'camera_zoom_out',
  ZOOM_STOP = 'camera_zoom_stop',
  GIMBAL_ROTATE = 'gimbal_rotate',
  PRESET_DIRECTION = 'preset_direction',
}

export enum ServiceMission {
  DEVICE_PREPARE = 'device_prepare',
  WAYLINE_START = 'wayline_start',
  RETURN_HOME = 'return_home',
}

export enum PageName {
  MAIN = 'Main',
  HOME = 'Home',
  MEMORY = 'Memory',
  ORDER = 'Order',
  ORDER_DETAIL = 'OrderDetail',
  PROFILE = 'Profile',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  BINDING_PHONE = 'BindingPhone',
  TUTORIALS = 'Tutorials',
  FLIGHT = 'Flight',
  VIDEO = 'Video',
  SELECT_WAYLINE = 'SelectWayline',
  AERIAL = 'Aerial',
  NOTICE = 'Notice',
  NOTICE_DETAIL = 'NoticeDetail',
  HOTPOT_DETAIL = 'HotpotDetail',
  LANGUAGE = 'Language',
  SETTING = 'Setting',
  MODIFY_PN = 'ModifyPN',
  SERVICE_AGREEMENT = 'ServiceAgreement',
  PRIVACY_POLICY = 'PrivacyPolicy',
  ABOUT_MSS = 'AboutMSS',
  WECHAT = 'Wechat',
}

export enum PaymentStatus {
  PAYED, // 已支付
  REFUND, // 退款中
  REFUND_COMPLETED // 已退款
}

export enum UserUtilization {
  NOT_USED_AND_READY = '01', // 首次使用并已到使用时间
  NOT_USED_AND_NOT_YET_READY = '00', // 首次使用还未到使用时间
  USED_AND_READY = '11', // 二次使用，并已到使用时间
  USED_AND_NOT_YET_READY = '10', // 二次使用，并没到使用时间
}

export enum StorageKey {
  SESSION_TOKEN = 'session_token',
  ACCESS_TOKEN = 'access_token',
  ORDER_ID = 'order_id',
  DOCK_SN = 'dock_sn',
  DRONE_SN = 'drone_sn',
  FLIGHT_STATUS = 'flight_status',
  FLIGHT_STEP = 'flight_step',
  FIRST_FLIGHT_ID = 'first_flight_id',
  SECOND_FLIGHT_ID = 'second_flight_id',
  JOURNEY_STATUS = 'journey_status',
  MQTT_OPTION = 'mqtt_option',
  USER_TOKENS = 'user_tokens',
  ACCOUNT = "account",
  LANGUAGE = 'language',
  JOURNEY_FLAG = 'journey_flag',
  WX_CODE_URL = 'wx_code_url',
  CURRENT_JOURNEY_STEP = 'current_journey_step',
  JOURNEY_STEP = 'journey_step',
  SESSION_SCHEDULE = 'session_schedule',
  MP_PAY_INFO = 'mp_pay_info'
}

/**
 * @description 订单事件枚举
 * @type PREPARE_COMPLETED: 一键备飞完成事件
 * @type TAKE_OFF_COUNTDOWN: 起飞倒计时事件
 * @type TAKE_OFF_COUNTDOWN_ENDS: 起飞倒计时结束事件
 * @type FIRST_WAYLINE_EXECUTING: 执行第一条航线成功事件
 * @type FIRST_WAYLINE_COMPLETED: 第一条航线完成事件
 * @type ENABLE_DRC_COUNTDOWN: 连接DRC倒计时事件
 * @type ENABLE_DRC_COUNTDOWN_ENDS: 连接DRC倒计时结束事件
 * @type ENABLE_DRC_COMPLETED: 连接drc成功事件
 * @type DISABLE_DRC_COUNTDOWN: 断开DRC倒计时事件
 * @type DISABLE_DRC_COUNTDOWN_ENDS: 断开DRC倒计时结束事件
 * @type DISABLE_DRC_COMPLETED: drc结束成功事件
 * @type SECOND_WAYLINE_COUNTDOWN: 执行第二条航线倒计时事件
 * @type SECOND_WAYLINE_COUNTDOWN_ENDS: 执行第二条航线倒计时结束事件
 * @type SECOND_WAYLINE_EXECUTING: 执行第二条航线成功事件
 * @type SECOND_WAYLINE_COMPLETED: 第二条航线完成事件
 */
export enum OrderEvent {
  SYNCHRONIZE_FLIGHT_ID_COMPLETED = 'SYNCHRONIZE_FLIGHT_ID_COMPLETED',
  PREPARE_COMPLETED = 'PREPARE_COMPLETED',
  TAKE_OFF_COUNTDOWN = 'TAKE_OFF_COUNTDOWN',
  TAKE_OFF_COUNTDOWN_ENDS = 'TAKE_OFF_COUNTDOWN_ENDS',
  FIRST_WAYLINE_EXECUTING = 'FIRST_WAYLINE_EXECUTING',
  FIRST_WAYLINE_COMPLETED = 'FIRST_WAYLINE_COMPLETED',
  ENABLE_DRC_COUNTDOWN = 'ENABLE_DRC_COUNTDOWN',
  ENABLE_DRC_COUNTDOWN_ENDS = 'ENABLE_DRC_COUNTDOWN_ENDS',
  ENABLE_DRC_COMPLETED = 'ENABLE_DRC_COMPLETED',
  DISABLE_DRC_COUNTDOWN = 'DISABLE_DRC_COUNTDOWN',
  DISABLE_DRC_COUNTDOWN_ENDS = 'DISABLE_DRC_COUNTDOWN_ENDS',
  DISABLE_DRC_COMPLETED = 'DISABLE_DRC_COMPLETED',
  SECOND_WAYLINE_COUNTDOWN = 'SECOND_WAYLINE_COUNTDOWN',
  SECOND_WAYLINE_COUNTDOWN_ENDS = 'SECOND_WAYLINE_COUNTDOWN_ENDS',
  SECOND_WAYLINE_EXECUTING = 'SECOND_WAYLINE_EXECUTING',
  SECOND_WAYLINE_COMPLETED = 'SECOND_WAYLINE_COMPLETED'
}

/**
 * @description 订单倒计时事件枚举
 * @type TAKE_OFF_COUNTDOWN: 起飞倒计时事件
 * @type ENABLE_DRC_COUNTDOWN: 连接DRC倒计时事件
 * @type DISABLE_DRC_COUNTDOWN: 断开DRC倒计时事件
 * @type SECOND_WAYLINE_COUNTDOWN: 执行第二条航线倒计时事件
 * @type UNKNOWN: 无倒计时事件时，为“UNKNOWN
 */
export enum OrderCountdownEvent {
  TAKE_OFF_COUNTDOWN = 'TAKE_OFF_COUNTDOWN',
  ENABLE_DRC_COUNTDOWN = 'ENABLE_DRC_COUNTDOWN',
  DISABLE_DRC_COUNTDOWN = 'DISABLE_DRC_COUNTDOWN',
  SECOND_WAYLINE_COUNTDOWN = 'SECOND_WAYLINE_COUNTDOWN',
  UNKNOWN = 'UNKNOWN'
}

/**
 * @type RESERVE: 预约
 * @type PAY: 支付
 * @type REPORT: 报到
 * @type SYNCHRONIZE_FLIGHT_ID: 同步flight_id
 * @type PREPARE: 一键备飞
 * @type FIRST_WAYLINE: 第一条航线
 * @type ENABLE_DRC: 开启DRC
 * @type DISABLE_DRC: 关闭DRC
 * @type SECOND_WAYLINE: 第二条航线
 * @type RETURN_HOME: 返航
 * @type DOWNLOAD_MEDIA: 下载、上传媒体
 */
export enum OrderStage {
  RESERVE = "RESERVE",
  PAY = "PAY",
  REPORT = "REPORT",
  SYNCHRONIZE_FLIGHT_ID = "SYNCHRONIZE_FLIGHT_ID",
  PREPAR = "PREPARE",
  FIRST_WAYLINE = "FIRST_WAYLINE",
  ENABLE_DRC = "ENABLE_DRC",
  DISABLE_DRC = "DISABLE_DRC",
  SECOND_WAYLINE = "SECOND_WAYLINE",
  RETURN_HOME = "RETURN_HOME",
  DOWNLOAD_MEDIA = "DOWNLOAD_MEDIA"
}

export enum OrderStageStatus {
  EXECUTING = 'EXECUTING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  CANCELING = 'CANCELING',
  CANCEL_FAILED = 'CANCEL_FAILED'
}

export enum FlightKey {
  FIRST = 0,
  SECOND = 1
}

export enum OrderStep {
  /**
   * 初始化, 初始化页面的状态
   */
  PREPARE = 0,
  /**
   * 控制步骤
   */
  CONTROL = 1,
  /**
   * 结束步骤
   */
  END = 2,
}