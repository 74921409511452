<script setup lang="ts">
import { router } from './router'
import { PageName, StorageKey } from './models/enums'
import { UserTokens } from './models/login'
const spotStore = useSpotStore()
const userStore = useUserStore()
const pageUrl = window.location.href
let _window: any
_window = window
const uploadData = reactive({
  uploadLog: true,
  timer: null as any,
  interval: 1000 * 60 * 3,
})

const setUserInfoByUrl = () => {
  const userInfoStr = new URL(window.location.href).searchParams.get('userInfo')
  if (!userInfoStr) return

  try {
    const userInfo = JSON.parse(userInfoStr) as UserTokens
    userStore.saveUserTokens(userInfo)
    userStore.setUserInformation(userInfo.username, userInfo.avatar, userInfo.phone_number || '')
    userStore.storageUserTokens()
    console.info('解析 url 上用戶信息', userInfo)
  } catch (err) {
    console.info('解析 url 上用戶信息失敗', userInfoStr)
  }
}

const checkForValidLogin = () => {
  const code = new URL(window.location.href).searchParams.get('code')
  const state = new URL(window.location.href).searchParams.get('state')
  console.info(`get wechat code:`, code)
  if (state) {
    const hotspot = state.split('#')[0]
    spotStore.setScanRecord(hotspot)
    console.log('hotspot from qrcode:', hotspot)
  }
  if (userStore.checkTokenIsExpired()) {
    console.info(`token expired`)
    userStore.logout()
    if (code) {
      userStore.userInfo.wechatCode = code
    }

    // 小程序未登錄時，不進行重定向（需要訪問協議頁面）
    if (!isWechatMiniProgram()) {
      router.replace({ name: PageName.HOME })
    }
  } else {
    console.info(`The token exists and has not expired`)
    userStore.setUserTokens()
  }
}

const createPageSpy = () => {
  const api = 'pagespy.internal.aklrobot.com'
  const { mode } = apiConfig
  const tokens = storage.getItem(StorageKey.USER_TOKENS) as UserTokens
  _window.$harbor = new _window.DataHarborPlugin()
  _window.$rrweb = new _window.RRWebPlugin()
  const plugins = [_window.$harbor, _window.$rrweb]
  plugins.forEach((p: any) => {
    _window.PageSpy.registerPlugin(p)
  })
  _window.$pageSpy = new _window.PageSpy({
    project: '大師航拍',
    api,
    clientOrigim: `https://${api}/`,
    autoRender: mode !== 'production',
    title: tokens ? tokens.username || navigator.userAgent : navigator.userAgent,
    serializeData: true,
  })
  if (uploadData.uploadLog && mode !== 'development') {
    uploadLog()
  }
}

// 定时上传日志
const uploadLog = () => {
  uploadData.timer = setInterval(() => {
    _window.$harbor.onOfflineLog('upload', true)
  }, uploadData.interval)
}

createPageSpy()
onMounted(async () => {
  // if (IS_DEV) {
  //   console.log('模拟登录', `是否开发环境：${IS_DEV}`)
  //   const { username, password, openid, phone_number } = envConfig
  //   const forceExpired = false
  //   const tokenExpired = userStore.checkTokenIsExpired(username, forceExpired)
  //   if (tokenExpired) {
  //     const res = await userAccountLogin(username, password)
  //     console.log(res)
  //     const { access_token, expires_at } = res
  //     storage.setItem(StorageKey.ACCOUNT, {
  //       openid,
  //       access_token,
  //       expires_at,
  //       phone_number,
  //     })
  //   }
  // }
  if (pageUrl.indexOf('/memory/hotpot-detail/video') > -1) {
    return
  }

  setUserInfoByUrl()
  if (import.meta.env.MODE !== 'development') {
    checkForValidLogin()
  }
  // 用于jssdk url签名参数
  storage.setItem(StorageKey.WX_CODE_URL, window.location.href)
})
onUnmounted(() => {
  clearInterval(uploadData.timer)
})
</script>

<template>
  <div class="app-page">
    <RouterView></RouterView>
  </div>
</template>

<style scoped lang="scss">
.app-page {
  height: 100vh;
}

:deep(.amap-logo) {
  display: none !important;
}

:deep(.amap-copyright) {
  display: none !important;
}
</style>
