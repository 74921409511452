import { appType } from "@/main"
import { ComponentCustomProperties } from 'vue'

declare module "vue" {
  export interface ComponentCustomProperties {
    $aMap: any
  }
}

let root: ComponentCustomProperties
let app: appType

export function useInstance(App: appType) {
  app = App
  root = app.config.globalProperties as ComponentCustomProperties
}
export function getRoot(): ComponentCustomProperties {
  return root
}

export function getApp(): appType {
  return app
}