import { HotspotData, HotspotMarkerData } from "@/models/spot"
import { defineStore } from "pinia"

const STORE_KEY = 'spot'

export const useSpotStore = defineStore({
    id: STORE_KEY,
    state: () => ({
        spotList: [] as any[],
        hotspotList: [] as HotspotMarkerData<HotspotData>[],
        hotspotData: {} as HotspotData,
        hotspotSkyPaths: [] as any[],
        scanRecord: {
            hotspot: ''
        }
    }),
    actions: {
        setScanRecord(hotspot: string) {
            this.scanRecord.hotspot = hotspot
        },
        setHotspotSkyPaths(hotspotSkyPaths: any[]) {
            this.hotspotSkyPaths = hotspotSkyPaths
        },
        setMarkerSpotList(spotList: any[]) {
            this.spotList = spotList
        }
    }
})