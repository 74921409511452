<script lang="ts" setup>
import NormalIcon from '@/assets/icons/normal_icon.png'
import SuccessIcon from '@/assets/icons/success_icon.png'
import ErrorIcon from '@/assets/icons/error_icon.png'
import LoginIcon from '@/assets/icons/login_icon.png'
import WarningIcon from '@/assets/icons/warning_icon.png'

interface Options {
  icon?: 'normal' | 'success' | 'error' | 'login' | 'warning'
  title?: string
  content?: string
  loding?: any
  buttonText?: string
}

const props = defineProps<Options>()

const state = reactive({
  isVisible: false,
  type: '',
})

const styleObject = reactive({
  background: 'rgba(0, 0, 0, 0.9)',
  paddingTop: '1.9rem',
  paddingBottom: '1.9rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}) as any

const { isVisible, type } = toRefs(state)

/**
 * 改变组件显隐
 * @param value true(显示) / false(隐藏)
 */
const setVisible = (value: boolean) => {
  isVisible.value = value
}

/**
 * 确认
 */
const handleConfirm = () => {
  isVisible.value = false
  type.value = 'confirm'
}

/**
 * 取消
 */
const handleCancel = ($event: any) => {
  if (props.buttonText) {
    if ($event.target.className === 'toast-container') {
      isVisible.value = false
      type.value = 'cancel'
    }
  }
}

defineExpose({
  setVisible,
  state,
})
</script>

<template>
  <div
    class="toast-container"
    @click.stop="handleCancel"
  >
    <div
      class="toast-box"
      :style="[icon ? '' : styleObject]"
    >
      <img
        v-if="icon === 'success'"
        :src="SuccessIcon"
        class="icon"
      />
      <img
        v-else-if="icon === 'error'"
        :src="ErrorIcon"
        class="icon"
      />
      <img
        v-else-if="icon === 'login'"
        :src="LoginIcon"
        class="icon"
      />
      <img
        v-else-if="icon === 'normal'"
        :src="NormalIcon"
        class="icon"
      />
      <img
        v-else-if="icon === 'warning'"
        :src="WarningIcon"
        class="icon"
      />
      <h3
        class="title"
        :style="{
          color: icon === 'error' ? '#FF7846' : '#42BDFF',
        }"
        v-if="title"
        v-html="title"
      ></h3>
      <div
        class="content"
        v-if="content"
      >
        <img
          v-if="loding"
          src=""
        />
        <span
          v-if="loding && content"
          style="width: 0.85rem"
        ></span>
        <span
          v-if="content"
          v-html="content"
        ></span>
      </div>
      <div
        class="button-box"
        v-if="buttonText"
      >
        <button
          class="button"
          v-html="buttonText"
          @click="handleConfirm"
        ></button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/mixin.scss';
@import '@/styles/variables.scss';
@import '@/styles/common.scss';
.toast-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .toast-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.7);
    border-radius: 1rem;
    width: 18.6rem;
    min-height: 7rem;
    padding: 3rem 0 2rem;
    box-sizing: border-box;
    .icon {
      width: 10rem;
      height: 10rem;
      top: -8.8rem;
      left: 50%;
      transform: translate(-50%);
      position: absolute;
    }
    .title {
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem; /* 125% */
      height: 2rem;
      margin-bottom: 1.2rem;
    }
    .content {
      color: $font-white-color;
      font-size: 1.6rem;
      font-weight: 400;
      width: 100%;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1.56rem;
        height: 1.69rem;
      }
      span {
        display: block;
      }
    }
    .button-box {
      margin-top: 2rem;
      .button {
        height: 2.8rem;
        line-height: 2.8rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: $font-white-color;
        display: flex;
        padding: 0.4rem 1.2rem;
        justify-content: center;
        align-items: center;
        border-radius: 2rem;
        background: $primary-blue-color;
      }
    }
  }
}
</style>
